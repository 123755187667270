.principal-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 !important;
    max-width: none !important;

    .header-list {
        padding: 16px;
        display: flex;
        justify-content: space-between;

        
        @media (max-width: 600px){  
            padding: 8px;
            display: block;

            .header-list-title {
                padding-bottom: 8px;
            }
        }
    }

    .info-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-right: 16px;
        
        @media (max-width: 600px){ 
            align-items: normal;
            flex-direction: column-reverse; 

            #total {
                margin-bottom: 16px;
            }
        }

        .footer-buttons {
            display: flex;
            flex-direction: row; 
    
            button {
                margin-right: 8px;
            }
        
            button:last-of-type {
                margin-right: 0;
            }
        }
        
    }
}

.container-box {
    margin: 0 288px 0 0 !important;
}
