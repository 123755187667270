.container {
    // background-color: #dcdcdc;
    background-image: linear-gradient(to bottom right, #dcdcdc, #1976d2);
    
    display: flex;
    // align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    flex-direction: column;
    // padding: 2%;
}

.main-container {
    // border-radius: 16px;
    display: flex;
    align-items: center;
    width: 100%;
    
}

.main {
    background-color: #f5f5f5;
    // border-radius: 16px;
    padding-left: 8px;
    padding-right: 8px;
    width: 40%;

    @media (max-width: 600px) {
        width: 100%;
        
    }

    height: calc(100vh - 40px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.main-image {
    display: flex;

    @media (max-width: 600px) {
        display: none;
    }
    
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #dcdcdc;
    opacity: 0.9;
    width: 100%;
    border-radius: 8px;
    margin-left: 8px;
    margin-right: 8px;
    .container-title {
        // flex: 2;
        padding-bottom: 8px;
        padding-top: 8px;
        border-radius: 8px;
        background-color: #1976d2;
        h2, h6 {
            color: #fff;
        }
        h6 {
            line-height: 1.1;
            margin: 8px;
        }
    }
    img {
        // flex: 1
        // border-bottom-right-radius: 8px;
        // border-bottom-left-radius: 8px;
        width: 700px;
        height: 700px;
    }
}

.footer {
    background-color: #dcdcdc;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}