.toolbar {
    min-height: 64px !important;
    margin: 0 !important;
}

.header-modal {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.main-container {
    padding:0  !important;
    margin: 0 !important;

    .main-box {
        padding: 8px 8px 0 8px !important;
    }
}

.MuiAccordionSummary-root:hover{
    background-color: #F5F5F5;
}


