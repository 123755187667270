.container-image {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: -16px;
    
    img {
        margin-top: 0;
        width: 650px;
        height: 650px;
    }

    @media (max-width: 600px){  
        img {
            margin-top: 0;
            width: 300px;
            height: 300px;
        }
    }
}

.principal-container-dashboard {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 64px);
    
    .container-title {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding-top: 16px;
        h2 {
            font-weight: 500;
            font-size: 36px;
            margin: 12px;
            // margin-top: 106px;
            text-align: center;
            padding: 0;
        }
        h6 {
            margin: 12px;
            color: #444;
            font-size: 16px;
            padding: 0;
            text-align: center;
            line-height: 20px;
        }
    
    }
}