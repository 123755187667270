.container-search {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: 0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1 0 auto;
    z-index: 1100;
    position: fixed;
    top: 0;
    outline: 0;
    right: 0;
    width: 300px;

    .container-inputs {
        display: block;
        width: 100%;
        margin-top: 8px;
        padding: 8px;
    }
}

.container-search-none {
    display: none;
}